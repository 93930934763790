var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-card',[_c('div',{staticClass:"mb-2"},[_c('h4',[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" เพิ่มผู้ช่วย ")])]),_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"ชื่อผู้ใช้","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_vm._v(" as ")]),_c('b-form-input',{attrs:{"id":"username","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]? 'กรุณากรอกชื่อผู้ใช้ ชื่อผู้ใช้ต้องมีอย่างน้อย 1 ตัวอักษร' :''))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"รหัสผ่าน","label-for":"a-password"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"a-password","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"placeholder":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]? 'กรุณากรอกรหัสผ่าน' :''))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"ยืนยันรหัสผ่าน","label-for":"ac-password"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"ac-password","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"placeholder":""},model:{value:(_vm.passValue),callback:function ($$v) {_vm.passValue=$$v},expression:"passValue"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]? 'กรุณายืนยันรหัสผ่าน' :''))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"ชื่อ","label-for":"firstname"}},[_c('validation-provider',{attrs:{"name":"firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstname","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.firstname),callback:function ($$v) {_vm.firstname=$$v},expression:"firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]? 'กรุณากรอกชื่อ' :''))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"นามสกุล","label-for":"lastname"}},[_c('validation-provider',{attrs:{"name":"lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastname","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? 'กรุณากรอกนามสกุล' :''))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"เบอร์โทรศัพท์","label-for":"tel"}},[_c('b-form-input',{attrs:{"id":"tel","type":"number","placeholder":""},model:{value:(_vm.tel),callback:function ($$v) {_vm.tel=$$v},expression:"tel"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Line ID","label-for":"line"}},[_c('b-form-input',{attrs:{"id":"line","text":"text","placeholder":""},model:{value:(_vm.line),callback:function ($$v) {_vm.line=$$v},expression:"line"}})],1)],1),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" สร้างผู้ช่วย ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }